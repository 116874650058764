<template>
  <div>
    <b-card v-if="viewMode === 'detailed'">
      <div class="mb-2">
        <b-row class="d-flex flex-row justify-content-between align-items-center p-0">
          <b-col sm="12" md="4" lg="4">
            <h4 v-if="$route.meta.indexObject?.datatableTitle">
              {{ $route.meta.indexObject.datatableTitle }}
            </h4>
            <slot v-else name="table-caption" />
          </b-col>
          <b-col
            class="d-flex flex-row align-items-center justify-content-end px-1"
            sm="12"
            md="8"
            lg="8"
          >
            <slot name="table-header-actions"></slot>

            <div name="table-header-filter" v-if="canShowFiltersButton" class="mr-2 ml-2">
              <feather-icon
                icon="FilterIcon"
                size="24"
                @click="showFiltersButton = true"
                class="cursor-pointer"
              />
            </div>
            <b-button variant="outline-primary" class="mr-1 text-nowrap" @click="kanbanView">
              Visão Kanban
            </b-button>
            <b-form-group class="mb-0 ml-1 mr-2">
              <b-input-group class="input-group-merge">
                <b-form-input id="email" v-model="search" :maxlength="50" name="email" />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <label class="mb-0 mr-1">Mostrar</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-flex flex-column mr-1"
            />
            <label class="">registros</label>
          </b-col>
        </b-row>
      </div>

      <div
        class="line-styles mt-2"
        :class="`border-${item.apto_operar.status.variant}`"
        v-for="(item, index) in items"
        :key="item.id"
      >
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between align-items-center m-1">
            <div class="d-flex">
              <h4 class="mb-0 font-weight-bolder mr-1">{{ item.nome }}</h4>
              <span
                ><router-link
                  :to="{ name: 'admin-dashboard.general-data', params: { id: item.id } }"
                  class="underline text-primary"
                  >{{ item.cnpj }}</router-link
                ></span
              >
            </div>
            <div class="d-flex align-items-center">
              <h5 class="mb-0 mr-1">{{ countDays(item.updated_at) }}</h5>
              <h5 class="mb-0 mr-1">|</h5>
              <div class="d-flex align-items-center">
                <feather-icon
                  :disabled="!item.user_name"
                  size="18"
                  icon="UserIcon"
                  class="mr-75"
                  v-b-tooltip.hover
                  :title="`${item.user_name} clique para copiar!`"
                  :stroke="item.user_name ? '#093272' : '#B4C0CC'"
                  @click="copyTextDetails('user_name', index)"
                />
                <feather-icon
                  :disabled="!item.email"
                  size="18"
                  icon="AtSignIcon"
                  class="mr-75"
                  v-b-tooltip.hover
                  :title="`${item.email} clique para copiar!`"
                  :stroke="item.email ? '#093272' : '#B4C0CC'"
                  @click="copyTextDetails('email', index)"
                />
                <feather-icon
                  :disabled="!item.celular"
                  size="18"
                  icon="SmartphoneIcon"
                  v-b-tooltip.hover
                  :title="`${item.celular} clique para copiar!`"
                  :stroke="item.celular ? '#093272' : '#B4C0CC'"
                  @click="copyTextDetails('celular', index)"
                />
              </div>
            </div>
          </div>
          <hr class="mx-1 mt-0 mb-0" />
        </div>

        <div class="d-flex align-items-center h-100 div-divider text-nowrap table-responsive">
          <div class="d-flex flex-column flex-fill">
            <h5 class="mb-2">Envio de convite</h5>
            <span v-if="item.envio_convite?.status?.variant === 'not_applied'"> --- </span>
            <div class="d-flex flex-column flex-fill align-items-start" v-else>
              <b-badge
                pill
                :class="`badge-light-${item.envio_convite?.status?.variant} border-${item.envio_convite?.status?.variant}`"
                ><span class="text-dark">{{ item.envio_convite?.status?.text }}</span></b-badge
              >
              <span class="mt-50">{{ formatDate(item.envio_convite?.data) }}</span>
            </div>
          </div>
          <div class="d-flex flex-column flex-fill">
            <h5 class="mb-2">Cadastro na plataforma</h5>
            <span v-if="item.cadastro_plataforma?.status?.variant === 'not_applied'"> --- </span>
            <div class="d-flex flex-column flex-fill align-items-start" v-else>
              <b-badge
                pill
                :class="`badge-light-${item.cadastro_plataforma?.status?.variant} border-${item.cadastro_plataforma?.status?.variant}`"
                ><span class="text-dark">{{
                  item.cadastro_plataforma?.status?.text
                }}</span></b-badge
              >
              <span class="mt-50">{{ formatDate(item.cadastro_plataforma?.data) }}</span>
            </div>
          </div>
          <div class="d-flex flex-column flex-fill">
            <h5 class="mb-2">Documentação e SCR</h5>
            <span v-if="item.documentacao_scr?.status?.variant === 'not_applied'"> --- </span>
            <div class="d-flex flex-column flex-fill align-items-start" v-else>
              <b-badge
                pill
                :class="`badge-light-${item.documentacao_scr?.status?.variant} border-${item.documentacao_scr?.status?.variant}`"
                ><span class="text-dark">{{ item.documentacao_scr?.status?.text }}</span></b-badge
              >
              <span class="mt-50">{{ formatDate(item.documentacao_scr?.data) }}</span>
            </div>
          </div>
          <div class="d-flex flex-column flex-fill">
            <h5 class="mb-2">Análise de limite</h5>
            <span v-if="item.analise_limite?.status?.variant === 'not_applied'"> --- </span>
            <div class="d-flex flex-column flex-fill align-items-start" v-else>
              <b-badge
                pill
                :class="`badge-light-${item.analise_limite?.status?.variant} border-${item.analise_limite?.status?.variant}`"
              >
                <span class="text-dark">{{ item.analise_limite?.status?.text }}</span>
              </b-badge>
              <span class="mt-50">{{ formatDate(item.analise_limite?.data) }}</span>
            </div>
          </div>
          <div class="d-flex flex-column flex-fill">
            <h5 class="mb-2">Envio de documentação</h5>
            <span v-if="item.envio_documentacao?.status?.variant === 'not_applied'"> --- </span>
            <div class="d-flex flex-column flex-fill align-items-start" v-else>
              <b-badge
                pill
                :class="`badge-light-${item.envio_documentacao?.status?.variant} border-${item.envio_documentacao?.status?.variant}`"
                ><span class="text-dark">{{ item.envio_documentacao?.status?.text }}</span></b-badge
              >
              <span class="mt-50">{{ formatDate(item.envio_documentacao?.data) }}</span>
            </div>
          </div>
          <div class="d-flex flex-column flex-fill">
            <h5 class="mb-2">Análise da documentação</h5>
            <span v-if="item.analise_documentacao?.status?.variant === 'not_applied'"> --- </span>
            <div class="d-flex flex-column flex-fill align-items-start" v-else>
              <b-badge
                pill
                :class="`badge-light-${item.analise_documentacao?.status?.variant} border-${item.analise_documentacao?.status?.variant}`"
                ><span class="text-dark">{{
                  item.analise_documentacao?.status?.text
                }}</span></b-badge
              >
              <span class="mt-50">{{ formatDate(item.analise_documentacao?.data) }}</span>
            </div>
          </div>
          <div class="d-flex flex-column flex-fill">
            <h5 class="mb-2">Apto para operar</h5>
            <span v-if="item.apto_operar?.status?.variant === 'not_applied'"> --- </span>
            <div class="d-flex flex-column flex-fill align-items-start" v-else>
              <b-badge
                pill
                :class="`badge-light-${item.apto_operar?.status?.variant} border-${item.apto_operar?.status?.variant}`"
                ><span class="text-dark">{{ item.apto_operar?.status?.text }}</span></b-badge
              >
              <span class="mt-50">{{ formatDate(item.apto_operar?.data) }}</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <b-row class="mt-2">
          <b-col
            cols="12"
            sm="9"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <b-button
              variant="flat-primary"
              class="mr-1 d-flex align-items-center"
              @click="xlsxExportAction"
            >
              Exportar para Excel <feather-icon icon="ShareIcon" size="18" class="ml-1" />
            </b-button>
            <span class="text-muted"> {{ showing }} </span>
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <datatable-filters-handler-sidebar
        :is-task-handler-sidebar-active="showFiltersButton"
        @closeSideBar="showFiltersButton = false"
        @updateFilterValues="updateFilterValues"
      />
    </b-card>
    <b-card class="kanban-body" v-else>
      <div class="mb-2">
        <b-row class="d-flex flex-row justify-content-between align-items-center p-0">
          <b-col sm="12" md="6" lg="6">
            <h4>Andamento das empresas na plataforma por etapa.</h4>
          </b-col>
          <b-col
            class="d-flex flex-row align-items-center justify-content-end px-1"
            sm="12"
            md="6"
            lg="6"
          >
            <b-button
              variant="outline-primary"
              class="mr-1 text-nowrap"
              @click="viewMode = 'detailed'"
            >
              Visão Detalhada
            </b-button>
            <b-form-group class="mb-0 ml-1 mr-2">
              <b-input-group class="input-group-merge">
                <b-form-input id="email" v-model="search" :maxlength="50" name="email" />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div class="asd">
        <b-card class="kanban-bg-color mb-0">
          <div class="d-flex div-divider-kanban text-nowrap mb-0">
            <div v-for="(status, index) in status" :key="index" class="flex-grow-1 mr-1 kanban-row">
              <div class="d-flex justify-content-between align-items-center mb-1 title">
                <h5 class="mb-0 font-weight-bolder">{{ formatStatus(status.name) || truncate }}</h5>
                <p class="mb-0">{{ status.companies.length }}</p>
              </div>

              <div class="d-flex flex-column">
                <b-card
                  v-for="(empresa, indexEmpresa) in status.companies"
                  :key="indexEmpresa"
                  class="w240 shadow"
                  :class="indexEmpresa + 1 === status.companies.length ? 'mb-0' : 'mb-2'"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <h5
                      class="mb-0 font-weight-bolder"
                    >
                      {{ truncate(empresa.razao_social) }}
                    </h5>
                    <feather-icon
                      icon="SearchIcon"
                      size="20"
                      class="text-primary"
                      @click="getCompanyDetails(empresa.id)"
                    />
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <p class="mb-1">{{ formatDate(empresa.data) }}</p>
                    <div v-if="empresa.status" v-html="empresa.status"></div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                      <div
                        v-b-tooltip.hover.html="tipMethod('user_name', empresa)"
                        :disabled="empresa.user_name == null"
                      >
                        <feather-icon
                          :disabled="true"
                          size="18"
                          icon="UserIcon"
                          class="mr-75"
                          :stroke="empresa.user_name ? '#093272' : '#B4C0CC'"
                          style="cursor: pointer"
                          :style="`cursor: ${empresa.user_name ? 'pointer;' : 'not-allowed;'}`"
                          @click="copyTextKanban('user_name', index, indexEmpresa)"
                        />
                      </div>
                      <div
                        v-b-tooltip.hover.html="tipMethod('email', empresa)"
                        :disabled="empresa.email == null || empresa.email == '' || empresa.email == undefined"
                      >
                        <feather-icon
                          :disabled="true"
                          size="18"
                          icon="AtSignIcon"
                          class="mr-75"
                          :stroke="empresa.email ? '#093272' : '#B4C0CC'"
                          style="cursor: pointer"
                          :style="`cursor: ${empresa.email ? 'pointer;' : 'not-allowed;'}`"
                          @click="copyTextKanban('email', index, indexEmpresa)"
                        />
                      </div>
                      <div
                        v-b-tooltip.hover.html="tipMethod('celular', empresa)"
                        :disabled="empresa.celular == null || empresa.celular == '' || empresa.celular == undefined"
                      >
                        <feather-icon
                          :disabled="true"
                          size="18"
                          icon="SmartphoneIcon"
                          :stroke="empresa.celular ? '#093272' : '#B4C0CC'"
                          style="cursor: pointer"
                          :style="`cursor: ${empresa.celular ? 'pointer;' : 'not-allowed;'}`"
                          @click="copyTextKanban('celular', index, indexEmpresa)"
                        />
                      </div>
                    </div>
                    <p class="mb-0">{{ empresa.dias > 0 ? `${empresa.dias} dias` : 'hoje' }}</p>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </b-card>

    <b-modal id="show-details" centered hide-header hide-footer class="">
      <div v-if="modalResult.nome" class="asd p-1">
        <div class="detail-header">
          <div class="row">
            <div class="col-11 mb-1">
              <h3 class="mb-0 text-blue">
                <strong class="cursor-pinter" @click="redirectTocompany(modalResult)">{{ modalResult.nome }}</strong>
              </h3>
            </div>
            <div class="col-1 mb-1">
              <feather-icon
                :disabled="true"
                size="23"
                icon="XIcon"
                class="mr-75"
                style="cursor: pointer"
                v-b-tooltip.hover
                :title="`Fechar modal`"
                @click="closeDetailsModal"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6 mb-1">
              <div class="d-flex justify-content-start">
                <div
                  v-b-tooltip.hover.html="tipMethod('user_name', modalResult)"
                  :disabled="modalResult.user_name == null || modalResult.user_name == '' || modalResult.user_name == undefined"
                >
                  <feather-icon
                    :disabled="true"
                    size="18"
                    icon="UserIcon"
                    class="mr-75"
                    v-b-tooltip.hover
                    :title="` clique para copiar!`"
                    :stroke="modalResult.user_name ? '#093272' : '#B4C0CC'"
                    @click="copyTextKanbanModal(modalResult.user_name)"
                    style="cursor: pointer"
                    :style="`cursor: ${modalResult.user_name ? 'pointer;' : 'not-allowed;'}`"
                  />
                </div>
                <div
                  v-b-tooltip.hover.html="tipMethod('email', modalResult)"
                  :disabled="modalResult.email == null || modalResult.email == '' || modalResult.email == undefined"
                >
                  <feather-icon
                    :disabled="true"
                    size="18"
                    icon="AtSignIcon"
                    class="mr-75"
                    v-b-tooltip.hover
                    :title="` clique para copiar!`"
                    :stroke="modalResult.email ? '#093272' : '#B4C0CC'"
                    @click="copyTextKanbanModal(modalResult.email)"
                    style="cursor: pointer"
                    :style="`cursor: ${modalResult.email ? 'pointer;' : 'not-allowed;'}`"
                  />
                </div>
                <div
                  v-b-tooltip.hover.html="tipMethod('celular', modalResult)"
                  :disabled="modalResult.celular == null || modalResult.celular == '' || modalResult.celular == undefined"
                >
                  <feather-icon
                    :disabled="true"
                    size="18"
                    icon="SmartphoneIcon"
                    v-b-tooltip.hover
                    :title="` clique para copiar!`"
                    :stroke="modalResult.celular ? '#093272' : '#B4C0CC'"
                    @click="copyTextKanbanModal(modalResult.celular)"
                    :style="`cursor: ${modalResult.celular ? 'pointer;' : 'not-allowed;'}`"
                  />
                </div>
              </div>
            </div>
            <div class="col-6 d-flex justify-content-end mb-1">
              <h4 class="text-gray f-400">{{ countDays(modalResult.updated_at) }}</h4>
            </div>
          </div>
        </div>
        <div class="border-top align-items-center mb-1 py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <p class="mb-0 text-black">Envio de convite</p>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="inviteBadge(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${inviteBadge(modalResult).variant} border-${
                  inviteBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ inviteBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="inviteBadge(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ formatDate(modalResult.envio_convite.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center mb-1 py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <p class="mb-0 text-black">Cadastro na plataforma</p>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="plataformRegistered(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${plataformRegistered(modalResult).variant} border-${
                  plataformRegistered(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ plataformRegistered(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="plataformRegistered(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ formatDate(modalResult.cadastro_plataforma.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center mb-1 py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <p class="mb-0 text-black">Documentação e SCR</p>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="documentSCRBadge(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${documentSCRBadge(modalResult).variant} border-${
                  documentSCRBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ documentSCRBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="documentSCRBadge(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ formatDate(modalResult.documentacao_scr.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center mb-1 py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <p class="mb-0 text-black">Análise de limite</p>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="aprovacaoLimiteBadge(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${aprovacaoLimiteBadge(modalResult).variant} border-${
                  aprovacaoLimiteBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ aprovacaoLimiteBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="aprovacaoLimiteBadge(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ formatDate(modalResult.analise_limite.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center mb-1 py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <p class="mb-0 text-black">Envio de documentação</p>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="envioDocumentacaoBadge(modalResult).variant === 'not_applied'">
                ---
              </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${envioDocumentacaoBadge(modalResult).variant} border-${
                  envioDocumentacaoBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ envioDocumentacaoBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="envioDocumentacaoBadge(modalResult).variant === 'not_applied'">---</span>
              <p class="mb-0" v-else>{{ formatDate(modalResult.envio_documentacao.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center mb-1 py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <p class="mb-0 text-black">Análise da documentação</p>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="analiseDocumentacaoBadge(modalResult).variant === 'not_applied'">
                ---
              </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${analiseDocumentacaoBadge(modalResult).variant} border-${
                  analiseDocumentacaoBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ analiseDocumentacaoBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="analiseDocumentacaoBadge(modalResult).variant === 'not_applied'"
                >---</span
              >
              <p class="mb-0" v-else>{{ formatDate(modalResult.analise_documentacao.data) }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="border-top align-items-center mb-1 py-50">
          <b-row>
            <b-col cols="4" class="text-nowrap">
              <p class="mb-0 text-black">Apto para operar</p>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="aptoOperarBadge(modalResult).variant === 'not_applied'"> --- </span>
              <b-badge
                v-else
                pill
                :class="`badge-light-${aptoOperarBadge(modalResult).variant} border-${
                  aptoOperarBadge(modalResult).variant
                }`"
              >
                <span class="text-dark">{{ aptoOperarBadge(modalResult).text }}</span>
              </b-badge>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <span v-if="aptoOperarBadge(modalResult).variant === 'not_applied'"> --- </span>
              <p class="mb-0" v-else>{{ formatDate(modalResult.apto_operar.data) }}</p>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BPagination,
  BModal,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatatableFiltersHandlerSidebar from '@/views/common/crud/components/DatatableFiltersHandlerSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import formatDistance from 'date-fns/formatDistance'
import { pt } from 'date-fns/locale/index'
import format from 'date-fns/format'

export default {
  name: 'OnboardingFollowUpIndex',
  components: {
    BButton,
    BBadge,
    BRow,
    BCol,
    BCard,
    BModal,
    vSelect,
    BFormGroup,
    BFormInput,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    DatatableFiltersHandlerSidebar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      status: [
        { name: 'importada', companies: [] },
        { name: 'envio_de_convite', companies: [] },
        { name: 'cadastro_na_plataforma', companies: [] },
        { name: 'documentacao_scr', companies: [] },
        { name: 'analise_limite', companies: [] },
        { name: 'envio_de_documentacao', companies: [] },
        { name: 'analise_documentacao', companies: [] },
        { name: 'apto_a_operar', companies: [] },
      ],
      viewMode: 'detailed',
      datatableFilterValues: {},
      skipCurrentPage: true,
      showFiltersButton: false,
      perPage: 5,
      perPageOptions: [5, 10, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'created_at',
      items: [],
      search: '',
      modalResult: {},
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    truncate(value) {
      return value.length > 23 ? `${value.substring(0, 23)}...` : value
    },
    formatDate(value) {
      if (!value) return '---'

      return format(new Date(value), 'dd/MM/yyyy HH:mm')
    },
    countDays(date) {
      const data1 = new Date(date)
      data1.setHours(0, 0, 0, 0)
      const data2 = new Date()
      data2.setHours(0, 0, 0, 0)

      const milisecondsDiff = data2 - data1
      const milissegundosPorDia = 24 * 60 * 60 * 1000

      const diferencaEmDias = Math.round(milisecondsDiff / milissegundosPorDia)

      if (diferencaEmDias === 0) return 'Hoje'

      return diferencaEmDias > 1 ? `${diferencaEmDias} dias` : `${diferencaEmDias} dia`
    },
    inviteBadge(item) {
      const badge = {
        aguardando_envio: {
          text: 'Aguardando envio',
          variant: 'warning',
        },
        convite_enviado: {
          text: 'Convite enviado',
          variant: 'success',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      return badge[item.envio_convite.status]
    },
    plataformRegistered(item) {
      const badge = {
        aguardando_cadastro: {
          text: 'Aguardando cadastro',
          variant: 'warning',
        },
        cadastro_realizado: {
          text: 'Cadastrado',
          variant: 'success',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      return badge[item.cadastro_plataforma.status]
    },
    documentSCRBadge(item) {
      const badge = {
        aguardando_envio: {
          text: 'Aguardando envio',
          variant: 'warning',
        },
        enviado_cliente: {
          text: 'Enviado pelo cliente',
          variant: 'success',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      return badge[item.documentacao_scr.status]
    },
    aprovacaoLimiteBadge(item) {
      return {
        aprovado: {
          text: 'Aprovado',
          variant: 'success',
        },
        reprovado: {
          text: 'Reprovado',
          variant: 'danger',
        },
        aguardando: {
          variant: 'warning',
          text: 'Aguardando',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }[item.analise_limite.status]
    },
    envioDocumentacaoBadge(item) {
      const badge = {
        aguardando_envio: {
          text: 'Aguardando envio',
          variant: 'warning',
        },
        documentacao_enviada: {
          text: 'Documentação enviada',
          variant: 'success',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      return badge[item.envio_documentacao.status]
    },
    analiseDocumentacaoBadge(item) {
      const badge = {
        aguardando: {
          text: 'Aguardando',
          variant: 'warning',
        },
        aprovado: {
          text: 'Aprovado',
          variant: 'success',
        },
        reprovada: {
          text: 'Reprovado',
          variant: 'danger',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      return badge[item.analise_documentacao.status]
    },
    aptoOperarBadge(item) {
      const badge = {
        sim: {
          text: 'Sim',
          variant: 'success',
        },
        nao: {
          text: 'Não',
          variant: 'danger',
        },
        not_applied: {
          variant: 'not_applied',
          text: 'not_applied',
        },
      }

      return badge[item.apto_operar.status]
    },
    async xlsxExportAction() {
      let params = this.params

      params.sort = `${this.sortBy}|${this.sortDesc ? 'desc' : 'asc'}`

      try {
        const { data } = await this.$store.dispatch('admin/downloadOnboardingXlsx', params)
        let url = URL.createObjectURL(data)
        let a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = 'onboarding_report.xlsx'
        a.click()
      } catch (error) {}
    },
    copyTextDetails(field, index) {
      this.status[index].companies

      this.$copyText(this.items[index][field]).then(() => {
        setTimeout(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Copiado!`,
              icon: 'ClipboardIcon',
              variant: 'success',
              text: `Texto copiado para sua área de transferência!`,
            },
          })
        }, 750)
      })
    },

    copyTextKanban(field, index, indexEmpresa) {
      this.$copyText(this.status[index].companies[indexEmpresa][field]).then(() => {
        setTimeout(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Copiado!`,
              icon: 'ClipboardIcon',
              variant: 'success',
              text: `Texto copiado para sua área de transferência!`,
            },
          })
        }, 750)
      })
    },
    toggleSelectedAllRows($event) {
      this.$refs.ccDatatable[$event === true ? 'selectAllRows' : 'clearSelected']()
    },
    onRowSelected(items) {
      this.selectedRows = items
      this.$emit('changeSelectedRows', items)
    },
    selectRow(row, value) {
      this.$refs.ccDatatable[value ? 'selectRow' : 'unselectRow'](row.index)
    },
    async getData() {
      if (this.viewMode == 'kanban') {
        let params = { search: this.search }
        const { data } = await this.$store.dispatch('app/getKanbanData', params)
        this.status = [
          { name: 'importada', companies: [] },
          { name: 'envio_de_convite', companies: [] },
          { name: 'cadastro_na_plataforma', companies: [] },
          { name: 'documentacao_scr', companies: [] },
          { name: 'analise_limite', companies: [] },
          { name: 'envio_de_documentacao', companies: [] },
          { name: 'analise_documentacao', companies: [] },
          { name: 'apto_a_operar', companies: [] },
        ]

        Object.keys(data).forEach((key) => {
          this.status.forEach((line) => {
            if (line.name == key) {
              line.companies = data[key]
            }
          })
        })
        this.$nextTick(() => {
          this.status.forEach((line) => {
            line.companies.forEach((empresa) => {
              if (empresa && empresa.status) {
                let badgeStatus = `<span class="badge badge-light-${empresa.variante} badge-text-dark border-${empresa.variante} badge-pill">${empresa.status}</span>`
                empresa.status = badgeStatus
              }
            })
          })
        })
      } else {
        let params = {
          path: this.$route.meta.indexObject?.serviceUrl,
          data: this.params,
        }

        params.data.sort = `${this.sortBy}|${this.sortDesc ? 'desc' : 'asc'}`

        try {
          const { data } = await this.$store.dispatch('app/getIndexData', params)
          let filteredData = data.data.filter((item) => {
            item.envio_convite.status = this.inviteBadge(item)
            item.cadastro_plataforma.status = this.plataformRegistered(item)
            item.documentacao_scr.status = this.documentSCRBadge(item)
            item.envio_documentacao.status = this.envioDocumentacaoBadge(item)
            item.analise_documentacao.status = this.analiseDocumentacaoBadge(item)
            item.apto_operar.status = this.aptoOperarBadge(item)
            item.analise_limite.status = this.aprovacaoLimiteBadge(item)
            return item
          })
          this.items = filteredData
          this.perPage = data.per_page || 5
          this.totalRows = data.total
          if (!this.skipCurrentPage) this.currentPage = data.current_page
          this.skipCurrentPage = true
        } catch (error) {}
      }
    },
    clearDatatable() {
      this.perPage = 5
      this.items = []
      this.totalRows = 0
      this.currentPage = 1
      this.sortBy = 'created_at'
    },
    showFiltersComponent() {
      this.$emit('show-filters-component')
    },
    refresh() {
      this.clearDatatable()
      this.getData()
    },
    updateFilterValues(values) {
      this.datatableFilterValues = values
    },
    async kanbanView() {
      this.viewMode = 'kanban'
      await this.getData()
    },
    async getCompanyDetails(empresa_id) {
      let params = {
        path: this.$route.meta.indexObject?.serviceUrl,
        data: { empresa_id: empresa_id },
      }
      const { data } = await this.$store.dispatch('app/getIndexData', params)
      this.modalResult = data.data[0]
      this.$bvModal.show('show-details')
    },
    closeDetailsModal() {
      this.$bvModal.hide('show-details')
    },
    formatStatus(name) {
      const statuses = {
        importada: 'Importada',
        envio_de_convite: 'Envio de convite',
        cadastro_na_plataforma: 'Cadastro na plataforma',
        documentacao_scr: 'Documentação e SCR',
        analise_limite: 'Análise de limite',
        envio_de_documentacao: 'Envio de documentação',
        analise_documentacao: 'Análise de documentação',
        apto_a_operar: 'Apto a operar',
      }

      return statuses[name]
    },
    redirectTocompany(empresaData) {
      this.$router.push({
        name: 'admin-dashboard.general-data',
        params: { id: empresaData.id },
      })
    },
    tipMethod(type, empresa) {
      switch (type) {
        case 'user_name':
          return `${empresa.user_name}</br>Clique para copiar`
        case 'email':
          return `${empresa.email}</br>Clique para copiar`
        case 'telefone':
          return `${empresa.telefone}</br>Clique para copiar`
        case 'celular':
          return `${empresa.celular}</br>Clique para copiar`
        default:
          return 'Clique para copiar'
      }
    },
    copyTextKanbanModal(text) {
      navigator.clipboard.writeText(text).then(() => {
        setTimeout(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Copiado!`,
              icon: 'ClipboardIcon',
              variant: 'success',
              text: `Texto copiado para sua área de transferência!`,
            },
          })
        }, 100)
      })
    },
  },
  computed: {
    showing() {
      let start = this.currentPage === 1 ? 1 : (this.currentPage - 1) * this.perPage + 1
      let end = start === 1 ? this.perPage : start + this.perPage - 1
      if (end > this.totalRows) end = this.totalRows
      if (this.totalRows === 0) start = 0

      return `Mostrando de ${start} a ${end} de ${this.totalRows} registros`
    },
    params() {
      let params = {
        per_page: this.perPage,
        page: this.currentPage,
        search: this.search,
      }

      if (Object.keys(this.datatableFilterValues).length)
        params = { ...params, ...this.datatableFilterValues }

      return params
    },
    canShowFiltersButton() {
      return (
        this.$route.meta.indexObject?.filters && this.$route.meta.indexObject?.filters.length > 0
      )
    },
  },
  watch: {
    $route() {
      this.clearDatatable()
      this.getData()
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue == oldValue) {
          this.currentPage = 1
          this.skipCurrentPage = false
        }
      },
      immediate: true,
    },
    params(newValue, oldValue) {
      if (newValue != oldValue) this.getData()
    },
  },
}
</script>

<style lang="scss">
#show-details {
  .modal-content {
    min-width: 600px;
  }
}

.w240 {
  min-width: 276px;
  max-width: 276px;
}

.kanban-bg-color {
  background-color: #eff4f6 !important;
  border: #dae1e8;
  height: 70vh;
  .card-body {
    overflow: auto;
  }
}

.per-page-selector {
  min-width: 80px;
}

.div-divider > div:not(:last-child) {
  position: relative;
}

.div-divider > div:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #becad4;
}

.div-divider > div {
  margin: 1rem 0 1rem 1rem;
  padding-right: 1rem;
}

.div-divider-kanban > div:not(:last-child) {
  position: relative;
}

.div-divider-kanban > div:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #becad4;
}

.div-divider-kanban > div {
  padding-right: 1rem;
}

.underline {
  text-decoration: underline;
  text-decoration-color: #003b65;
}

.line-styles {
  border: 1px solid #dae1e8;
  border-radius: 6px;
}

.divider-vertical {
  border-left: 1px solid #dee2e6; /* altere a cor conforme necessidade */
  height: 100%;
  margin-left: 15px; /* ajuste a margem conforme necessidade */
}
.kanban-row {
  min-width: 290px;
  max-width: 290px;
}

.div-divider-kanban {
  .card {
    .card-body {
      padding: 8px;
    }
  }
}

.title {
  min-width: 276px;
  max-width: 276px;
  padding: 0 8px 0 8px;
}

.kanban-body {
  .card-body {
    height: 75vh;
    flex: 1;
  }
}

.text-blue {
  color: rgba(9, 50, 114, 1)
}

.text-black {
  color: rgba(36, 42, 50, 1);
}

.f-400 {
  font-weight: 400;
}

.cursor-pinter {
  cursor: pointer;
}

.ds-none {
  display: none;
}

.ds-block {
  display: block;
}

.tooltip-inner {
  background-color: rgba(102, 120, 139, 1);
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: rgba(102, 120, 139, 1);
}
</style>
